.heading
  font-weight: 500
  margin: 2em 0 0 0
  color: #f9f9f9

.container
  display: flex
  flex-direction: column
  align-items: center
  justify-content: start
  font-size: 1.5rem
  font-weight: 300
  line-height: 1.5
  text-align: center
  padding: 0 1rem
  width: 50%

p
  font-size: 1.2rem
  font-weight: 300
  margin: 0.5rem 0
  color: #f9f9f9
