$underlineAnim: text-decoration 0.5s ease

.cardHolder
  height: auto
  margin: 2rem
  display: flex
  align-items: center
  justify-content: space-evenly
  flex-direction: row

  @media (max-width: 768px)
    flex-direction: column

.linkCardHolder
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column

.card
  height: auto
  flex: 1 1 100%
  max-width: 18em
  margin: 1em
  background: #090909
  padding: 0.75rem
  color: #f5f5f5
  border-radius: 15px
  display: flex
  flex-direction: column
  justify-content: space-between
  min-height: 15em
  align-items: stretch
  box-shadow: none
  transition: box-shadow 1s ease-in
  &>p
    color: #f5f5f5
  &:hover
    box-shadow: #713572 0 0 20px 5px
    transition: box-shadow 0.25s ease-in

.linkCard
  flex: 1 1 100%
  max-width: 14em
  background-color: #f1f1f1
  box-shadow: #f2f2f2 2px 2px 15px
  padding: 0.75rem
  color: black
  margin: 0.75em
  border-radius: 15px
  &>p
      color: black

.cardLink
  color: deepskyblue
  font-size: 18px
  font-weight: 400
  text-decoration: underline transparent
  text-shadow: none
  transition: text-shadow 0.25s ease-in
  &:hover
    text-decoration: underline
    text-shadow: #007bff 3px 3px 7px
    transition: text-shadow 0.25s ease-in


.linkTitle
  color: #131313
  font-size: 18px
  font-weight: normal
  text-decoration: transparent underline
  transition: $underlineAnim
  &:hover
    text-decoration: underline
    transition: $underlineAnim