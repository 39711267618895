.container
  display: flex
  flex-direction: column
  align-items: center
  width: 100%
  text-align: center
  margin: 0.25rem

.title
  font-family: 'Josefin Sans', sans-serif
  font-size: 2rem
  font-weight: 500
  margin: 1em 0 0 0

.subtitle
  font-weight: 300
