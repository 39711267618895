.impressum
  display: flex
  flex-direction: column
  align-items: center
  justify-content: start
  font-size: 1.5rem
  font-weight: 300
  line-height: 1.5
  text-align: center
  padding: 0 1rem
  a
    color: #007bff
    text-decoration: none
    &:hover
      text-decoration: underline