@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap')

body
  font-family: "Josefin Sans", sans-serif
  color: #f2f2f2
  margin: 0
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  height: 100%
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  text-align: center
  overflow: auto
  animation: gradient 15s ease infinite
  background-size: 400% 400%
  background-color: #131313

  @keyframes gradient
    0%
      background-position: 0 0
    50%
      background-position: 100% 100%
    100%
      background-position: 0 0