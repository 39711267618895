$wave-height: 11em

.wave
  background: linear-gradient(90deg, rgba(123, 209, 255, 0.15) 25%, rgba(147, 123, 255, 0.15) 50%, rgba(255, 123, 233, 0.15) 75%, rgba(255, 123, 123, 0.15) 100%)
  border-radius: 1000% 1000% 0 0
  position: fixed
  width: 200%
  height: $wave-height
  animation: wave 20s -1s linear infinite
  transform: translate3d(0, 0, 0)
  opacity: 0.8
  bottom: 0
  margin: 0
  left: 0
  z-index: -1
  box-shadow: #f4f4f4 -25px -3px 13px

.wave:nth-of-type(2)
  bottom: -1.25em
  animation: wave 22s linear normal infinite
  opacity: 0.7

.wave:nth-of-type(3)
  bottom: -2.5em
  animation: wave 24s -1s reverse infinite
  opacity: 0.9

.wave:nth-of-type(4)
  bottom: -3.75em
  animation: wave 26s -1s normal infinite
  opacity: 0.8

.wave:nth-of-type(5)
  bottom: -4em
  animation: wave 28s -1s reverse infinite
  opacity: 0.9

@keyframes wave
  2%
    transform: translateX(-2%)

  25%
    transform: translateX(-25%)
    box-shadow: #f4f4f4 -25px -3px 7px

  50%
    transform: translateX(-50%)

  75%
    transform: translateX(-25%)

  100%
    transform: translateX(-2%)
    box-shadow: #fefefe -25px -3px 7px

.sticky
  position: fixed
  bottom: 0
  width: 200%
  height: $wave-height
  z-index: 100
  background-color: #131313

.waveSpacer
  width: 100%
  height: $wave-height + 1em
  margin: 0
