$gradient-shadow: linear-gradient(45deg, #fb0094,#0000ff,#00ff00,#ffff00,#ff0000,#fb0094,#0000ff,#00ff00,#ffff00,#ff0000)

.navbar
    position: sticky
    top: 0
    width: 100%
    background-color: #131313
    display: flex
    justify-content: space-around
    flex-direction: row
    padding: 20px 0
    box-shadow: #713572 5px 5px 10px
    margin: 0

.navlink
    color: white
    text-decoration: none
    font-size: 1.2rem
    padding: 0 10px

.activeNavlink
    text-decoration: none
    font-size: 1.2rem
    padding: 0 10px
    color: #c55ac6 !important
    text-shadow: 0 0 6px #a04aa1 !important

.title
    color: white
    background-color: #202020
    font-size: 1.5rem
    font-weight: bold
    padding: 15px
    margin: 0